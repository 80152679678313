import { addModuleName } from '@/tools/addModuleNames';

const MODULE_NAME = 'logs';

enum mutationsNames {
    SET_LOGS = 'SET_LOGS',
    SET_TOTAL_LOGS = 'SET_TOTAL_LOGS',
}

enum actionsNames {
    getLogs = 'getLogs',
}

const logsMutations = {
    [mutationsNames.SET_LOGS]: addModuleName(
        MODULE_NAME,
        mutationsNames.SET_LOGS,
    ),
    [mutationsNames.SET_TOTAL_LOGS]: addModuleName(
        MODULE_NAME,
        mutationsNames.SET_TOTAL_LOGS,
    ),
};

const logsActions = {
    [actionsNames.getLogs]: addModuleName(
        MODULE_NAME,
        actionsNames.getLogs,
    ),
};

export {
    mutationsNames,
    logsMutations,
    actionsNames,
    logsActions,
};