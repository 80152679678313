import { ActionTree } from 'vuex';

import rest from '@/api/index';

import { ILogState } from './types';
import { ComposedState } from '@/types/index';

import { actionsNames, mutationsNames } from './names';

export const actions: ActionTree<ILogState, ComposedState> = {
    async [actionsNames.getLogs]({ commit }, params) {
        const res = await rest.getLogs(params);

        if (res && res.status === 200 && res.data) {
            commit(mutationsNames.SET_LOGS, res.data.items);
            commit(mutationsNames.SET_TOTAL_LOGS, res.data.total);
        }
    },
};